import React, {useRef, useEffect, createRef} from "react";
import { Seo } from "../../components/Seo";
import {graphql} from 'gatsby';
import Carousel from "../../components/Projects/Carousel";
import { StaticImage } from "gatsby-plugin-image";
import ContactForm from "../../components/ContactForm";

const TinyTreehouseProject = ({data}) => {
  const textRef = useRef([]);
  const imageRef = useRef([]);
  for(let i = 0; i < 3; i++) {
    textRef.current[i] = createRef();
    imageRef.current[i] = createRef();
  }

  const resizeSection = () => {
    for(const[k,i] of Object.entries(textRef.current)) {
      if(i.current || false) {
        let mheight = i.current.offsetHeight + 40;
        
        imageRef.current[k].current.style.maxHeight = `${mheight}px`;
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeSection);
    resizeSection();
  }, []);

  const projects = data.allPrismicMixedContentProject.edges.map((i,k) => ({
    to: i.node.uid,
    name: i.node.data.title,
    description: i.node.data.description,
    src: i.node.data.cover_image.url
  }))
  return (
    <main>
      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[0]}
            className="py-24 px-16 xl:px-32">
            <h1 className="font-black text-4xl lg:text-5xl mb-12">
              Tiny Treehouses.<br />
              Inspired locally.<br />
              Manufactured in China.
            </h1>
            <p className="text-xl lg:text-2xl mb-8">
              We adore working with Tiny Treehouses, who have come up with a stupendous idea of making DIY model treehouse kits for your pot plants.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Lars, the founder of Tiny Treehouses says, ‘Launching a new brand with a new product takes an incredible amount of work, time and financial resources! I can confidently say I am far better off financially and have much more time to develop my product and business having partnered with Sourci.’
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Here's how we made amazing happen for Tiny Treehouses.
            </p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[0]}>
              <StaticImage src="../../images/tiny-treehouses-1.webp"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container grid-cols-1 lg:grid-cols-4 mx-auto px-6 grid text-center gap-x-12 gap-y-12">
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>$300K</span>
            </p>
            <p className="font-black text-xl">FUNDING</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>9,000</span>
            </p>
            <p className="font-black text-xl">UNITS ORDERED</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>&#8595;{'<'}2%</span>
            </p>
            <p className="font-black text-xl">DEFECT RATE</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>ZERO</span>
            </p>
            <p className="font-black text-xl">HEADACHES</p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div className="flex h-full"
            ref={imageRef.current[1]}>
              <StaticImage src="../../images/tiny-treehouses-2-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
          <div ref={textRef.current[1]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
              How scaling is difficult when alone
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              Tiny Treehouses was first conceived and launched on one of the biggest crowd funding websites in the world, Kickstarter. Initially the goal was to raise AUD$20,000.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              By September 2020, the campaign raised over AUD$300,000. This amazing result was overshadowed by the daunting task of producing over 9,000 units. The timeline looked impossible to achieve, with the plan of using an industrial laser machine for each individual one. We heart control.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <h3 className="font-black text-5xl lg:text-6xl">
            Start to pull the strings. Find answers. Contacts. Leads. Forget about the person who knows a person.
          </h3>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[2]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
              Save time, money and more importantly - headaches
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              Sourci was then commissioned by Tiny Treehouses to manage the product development, source a manufacture, handle the quality control and arrange the importing back into Australia. The entire end-to-end production process allowed Tiny Treehouses to concentrate on growing their business within the product development stage and gave Lars piece of mind that experts had people speaking the native language, with knowledge around all aspects of manufacturing and supply chain logistics. Sourci was able to produce accurate samples that would otherwise taken ten times longer. This has dramatically reduced the time to deliver the products to the awaiting KickStarter backers.
            </p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[2]}>
              <StaticImage src="../../images/tiny-treehouses-3-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-32">
        <div className="xl:container lg:grid lg:grid-cols-2 mx-auto px-6">
          <div className="text-black">
            <p className="text-4xl md:text-6xl font-black mb-8">The time<br /> is now.</p>
            <p className="text-black text-4xl mb-8 font-black">Let's make amazing<br /> happen today.</p>
            <p className="text-3xl mb-2">
              <a href="tel:+611300768724"
                className="hover:underline">1300 SOURCI</a>
            </p>
            <p className="text-3xl mb-2">
              <a href="mailto:hello@sourci.com.au"
                className="hover:underline">hello@sourci.com.au</a>
            </p>
          </div>
          <div className="mt-12 lg:mt-0">
            <ContactForm bg="light"
              location="Case Study - Tiny Treehouses" />
          </div>
        </div>
      </section>
      <Carousel projects={projects}
        project="tiny-treehouses"
        title="Check out our other Projects" />
    </main>
  )
}

export const query = graphql`
  query {
    allPrismicMixedContentProject {
      edges {
        node {
          data {
            title
            description
            cover_image {
              url
            }
          }
          uid
        }
      }
    }
  }
` 

export default TinyTreehouseProject;

export function Head() {
  return (
    <Seo title="Tiny Treehouses | Our Projects | Sourci"
      description="Discover how Sourci managed the product development process of Tiny Treehouses."
      canonical="/projects/tiny-treehouses" />
  )
}